<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">账号资料</h4>
      <!-- 登录信息 -->
      <div class="part">
        <h3>
          登录信息
        </h3>
        <el-form :model="form" :rules="rules" ref="accountForm" label-width="150px">
          <el-row>
            <el-col :span="14">
              <el-form-item label="账号：" prop="account">
                <span :title="account" v-if="!isEditAccount">{{ account }}</span>
                <el-input v-model="form.account" v-else></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="account === origin_username">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="editAccount" v-if="!isEditAccount">修改</el-button>
                <div v-else>
                  <el-button type="primary" size="small" v-bind:disabled="account === form.account" @click="submitForm('accountForm')">确定</el-button>
                  <el-button size="small" @click="cancel_edit('accountForm')">取消</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :model="form" :rules="rules" ref="telForm" label-width="150px">
          <el-row>
            <el-col :span="14">
              <el-form-item label="手机号：" prop="tel">
                <span v-if="!isEditTel">{{ tel }}</span>
                <el-input v-model="form.tel" v-else></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="editTel">修改</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :model="form" :rules="rules" ref="mailForm" label-width="150px">
          <el-row
            element-loading-text="邮件发送中"
                              element-loading-spinner="el-icon-loading"
                             v-loading="loading"
          >
            <el-col :span="14">
              <el-form-item label="邮箱：" prop="email">
                <span :title="email" v-if="!isEditMail">{{ email }}</span>
                <el-input v-model="form.email" v-else></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="editMail" v-if="!isEditMail">修改</el-button>
                <div v-else>
                  <el-button type="primary" size="small" @click="submitForm('mailForm')">确定</el-button>
                  <el-button size="small" @click="cancel_edit('mailForm')">取消</el-button>
                </div>
                <span class="tip" v-if="!isEditMail && email && !email_verified">(邮件已发送，未激活，请24小时内验证。<el-button type="text" size="small" @click="sendMail">重新发送</el-button>)</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 个人信息 -->
      <div class="part">
        <h3>
          个人信息
          <el-button type="primary" size="mini" @click="editInfo" v-if="!isEditInfo">修改</el-button>
        </h3>
        <el-form :model="form" :rules="rules" ref="infoForm" label-width="150px">
          <el-form-item label="姓名：" prop="realname">
            <span v-if="!isEditInfo">{{ realname }}</span>
            <el-input v-model.trim="form.realname" v-else></el-input>
          </el-form-item>
          <el-form-item label="职业：" prop="job">
            <span v-if="!isEditInfo">{{ job_identity }}</span>
            <el-radio-group v-model="form.job" v-else>
              <el-radio label="office">职员</el-radio>
              <el-radio label="student">学生</el-radio>
              <el-radio label="teacher">教师</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="单位/学校：" prop="work_place">
            <span v-if="!isEditInfo">{{ work_place }}</span>
            <el-input v-model.trim="form.work_place" v-else></el-input>
          </el-form-item>
          <el-form-item label="岗位/专业：" prop="job_name">
            <span v-if="!isEditInfo">{{ job_name }}</span>
            <el-input v-model.trim="form.job_name" v-else></el-input>
          </el-form-item>
          <el-form-item v-if="isEditInfo">
            <el-button type="primary" size="small" @click="submitForm('infoForm')">确定</el-button>
            <el-button size="small" @click="cancel_edit('infoForm')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 个人信息 -->
      <div class="part">
        <h3>账号绑定</h3>
        <el-form label-width="150px">
          <el-row>
            <el-col :span="14">
              <el-form-item label="QQ：">
                <span v-if="qq" :title="qq.account_name">{{ qq.account_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="unBindThirdParty('qq')" v-if="qq">解除绑定</el-button>
                <el-button type="text" size="small" @click="bindThirdParty('qq')" v-else>点击绑定</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="14" v-if="false">
              <el-form-item label="微信：">
                <span v-if="weixin" :title="weixin.account_name">{{ weixin.account_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="false">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="unBindThirdParty('weixin')" v-if="weixin">解除绑定</el-button>
                <el-button type="text" size="small" @click="bindThirdParty('weixin')" v-else>点击绑定</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="新浪微博：">
                <span v-if="weibo" :title="weibo.account_name">{{ weibo.account_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="unBindThirdParty('weibo')" v-if="weibo">解除绑定</el-button>
                <el-button type="text" size="small" @click="bindThirdParty('weibo')" v-else>点击绑定</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="百度：">
                <span v-if="baidu" :title="baidu.account_name">{{ baidu.account_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="unBindThirdParty('baidu')" v-if="baidu">解除绑定</el-button>
                <el-button type="text" size="small" @click="bindThirdParty('baidu')" v-else>点击绑定</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="GitHub：">
                <span v-if="github" :title="github.account_name">{{ github.account_name }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item class="edit_btns">
                <el-button type="text" size="small" @click="unBindThirdParty('github')" v-if="github">解除绑定</el-button>
                <el-button type="text" size="small" @click="bindThirdParty('github')" v-else>点击绑定</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 更换手机号 -->
    <el-dialog
      title="更换手机号"
      v-model="editCellPhoneModal.visible" @closed="closeEditPhoneModal"
      width="500px" custom-class="forget_pwd_modal">
      <div v-if="editCellPhoneModal.visible">
        <el-alert v-if="editCellPhoneModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ editCellPhoneModal.error }}</el-alert>
        <el-form :model="editCellPhoneModal.form" :rules="rules" ref="editCellPhoneForm">
          <el-form-item prop="addtel">
            <el-input placeholder="请输入手机号" class="input_tel" v-model="editCellPhoneModal.form.addtel">
              <template #prefix>
                CN + 86
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="yzm" v-if="!editCellPhoneModal.isVerify">
            <div class="get_yzm">
              <el-input :placeholder="editCellPhoneModal.form.yzm_placeholder"
                        v-model="editCellPhoneModal.form.yzm"></el-input>
              <el-button type="text" @click="getEditSms()" v-if="!editCellPhoneModal.isGetYzm">获取验证码</el-button>
              <span v-else>{{ editCellPhoneModal.countdown }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="verify" v-else>
            <PuzzleVerify :width="460" :height="200" @status="getEditPhoneStatus" @result="getEditPhoneResult"></PuzzleVerify>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="editCellPhoneModal.visible = false">取 消</el-button>
            <el-button type="primary" @click="submitEditPhoneForm()">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import PuzzleVerify from 'components/common/PuzzleVerify'
  import { showConfirmMessageBox, showMessage } from 'api/base/message'
  import { GetUserState, userBindThirdParty, userUnBindThirdParty, AddCellPhone, sendUserEmail } from 'api/user/auth/login'
  import { checkPhone, checkEmail, checkPassword, checkUserName, checkRealName } from 'utils/common'
  import { updateUserInfo, addSystemUser, GetUserInfo, CheckUserInfoExist } from 'api/user/user/user'
  import { checkPhoneExist, SendSms, RegisterUser } from 'api/user/auth/register'
  export default {
    components: {
      PuzzleVerify,
    },
    data() {
      let that = this;
      let checkUserNameExistRule = (rule, value, callback) => {
        if (!value || value === that.account) return callback();
        let data = {"username": value, "site_id": 1}
        return CheckUserInfoExist(data, callback, "账号")
      }

      let checkPhoneExistRule = (rule, value, callback) => {
        if (!value || value === that.tel) return callback(new Error('请填写新的手机号码'));
        checkPhoneExist(value).then((res) => {
          if (!res.result) {
            callback()
          } else {
            return callback(new Error('手机号码已经被注册'))
          }
        }).catch(err => {
          return callback(new Error('服务异常，请稍后再试'))
        });
      }

      let checkUserEmailExistRule = (rule, value, callback) => {
        if (!value || value === that.email) return callback();
        let data = {"email": value, "site_id": 1}
        return CheckUserInfoExist(data, callback, "邮箱")
      }

      return {
        loading: false,
        isEditAccount: false,
        isEditTel: false,
        isEditMail: false,
        isEditInfo: false,
        user_id: '',
        account: '',
        origin_username: '',
        tel: '',
        email: '',
        job_identity: '',
        job: '',
        work_place: '',
        realname: '',
        job_name: '',
        email_verified: '',
        qq: '',
        weixin: '',
        weibo: '',
        baidu: '',
        github: '',
        form: {
          account: '',
          tel: '',
          email: '',
          job: '',
          work_place: '',
          realname:'',
          job_name: '',
        },
        editCellPhoneModal: {
          error: '',
          visible: false,
          isGetYzm: false,
          isVerify: false,
          countdown: '等待 60 s',
          form: {
            addtel: '',
            yzm: '',
            yzm_placeholder: '请输入短信验证码',
            verify: '',
            user_id: ''
          }
        },
        rules: {
          account: [
            { required: true, message: '请填写账号', trigger: 'blur' },
            { required: true, validator: checkUserName, trigger: 'blur' },
            { required: true, validator: checkUserNameExistRule, trigger: 'blur' }
          ],
          job_name: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          work_place: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          realname: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          addtel: [
            { required: true, validator: checkPhone, trigger: 'blur' },
            { required: true, validator: checkPhoneExistRule, trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请填写邮箱', trigger: 'blur' },
            { min: 1, max: 250, message: '邮箱长度在1到250个字符内', trigger: 'blur' },
            { required: true, validator: checkEmail, trigger: 'blur' },
            { required: true, validator: checkUserEmailExistRule, trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      cancel_edit(option) {
        switch(option){
          case 'accountForm': {
            this.isEditAccount = !this.isEditAccount;
            this.$refs[option].resetFields();
          } break;
          case 'mailForm': {
            this.isEditMail = !this.isEditMail;
            this.$refs[option].resetFields();
          } break;
          case 'infoForm': {
            this.isEditInfo = !this.isEditInfo;
            this.$refs[option].resetFields();
          } break;
          default: {
              console.log('unknown command');
          } break;
        }
      },
      editAccount() {
        let msg = "账号名称只可以修改一次，确认修改吗？"
        showConfirmMessageBox(msg).then(() => {
          this.isEditAccount = !this.isEditAccount;
          this.form.account = this.account;
        }).catch(res => {})
      },

      editTel() {
        this.editCellPhoneModal.visible = true;
      },

      editMail() {
        this.isEditMail = !this.isEditMail;
        this.form.email = this.email;
      },

      editInfo() {
        this.isEditInfo = !this.isEditInfo;
        this.form.job = this.job;
        if (!this.form.job) {
          this.form.job = 'office'
        }
        this.form.job_name = this.job_name;
        this.form.work_place = this.work_place;
        this.form.realname = this.realname;
      },

      updateUserInfoMethod(data) {
        updateUserInfo(this.user_id, data)
          .then((res) => {
            this.loading = false;
            if (res.result === 0) {
              this.$router.go(0);
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          }).catch((err) => {
          this.loading = false;
          this.$alert('服务异常，请稍后再试', '提示', {
                confirmButtonText: '确定',
          });
        })
      },

      sendMail() {
        sendUserEmail()
          .then((res) => {
            if (res.result === 0) {
              this.$alert('已经发送，请验证', '提示', {
                confirmButtonText: '确定',
              });
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
              });
            }
          }).catch((err) => {
          this.$alert('服务异常，请稍后再试', '提示', {
                confirmButtonText: '确定',
          });
        })
      },

      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              switch(formName){
                case 'accountForm': {
                  this.updateUserInfoMethod({"username": this.form.account});
                } break;
                case 'mailForm': {
                  this.loading = true;
                  this.updateUserInfoMethod({"email": this.form.email});
                } break;
                case 'infoForm': {
                  this.updateUserInfoMethod({"job": this.form.job, "job_name": this.form.job_name, "work_place": this.form.work_place, "realname": this.form.realname});
                } break;
                default: {
                    console.log('unknown command');
                } break;
              }
            } else {
              return false;
            }
          });
      },

      getEditSms() {
        this.$refs.editCellPhoneForm.validateField(['addtel'], (valid) => {
          if (!valid) {
            this.editCellPhoneModal.isVerify = true;
          }
        });
      },
      getEditPhoneStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.editCellPhoneModal.form.verify = 'success';
        } else {
          this.editCellPhoneModal.form.verify = '';
        }
      },
      getEditPhoneResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.editCellPhoneModal.isVerify = false;
          SendSms(this.editCellPhoneModal.form.addtel).then((res) => {
            if (!res.result){
              this.editCellPhoneModal.form.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getAddPhoneYzm();
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getAddPhoneYzm(surplus_time) {
        // 展示等待时间
        this.editCellPhoneModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.editCellPhoneModal.countdown = `等待 ${ time } s`
          } else {
            clearInterval(countdown);
            this.editCellPhoneModal.isGetYzm = false;
            this.editCellPhoneModal.countdown = `等待 60 s`;
          }
        }, 1000)
      },
      submitEditPhoneForm() {
        let that = this;
        this.$refs.editCellPhoneForm.validate((valid) => {
          if (valid) {
            AddCellPhone(this.editCellPhoneModal.form)
              .then((res) => {
                if (!res.result) {
                  this.$router.go(0);
                } else {
                  this.editCellPhoneModal.error = res.msg;
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      closeEditPhoneModal() {
        // console.log(this.$refs.editCellPhoneForm)
        // this.$refs.editCellPhoneForm.resetFields();
        this.editCellPhoneModal.visible = false;
        this.editCellPhoneModal.error = '';
      },

      bindThirdParty(type) {
        userBindThirdParty(this.user_id, type)
      },

      unBindThirdParty(type) {
        let msg = "确定要解除绑定吗？"
        let that = this;
        showConfirmMessageBox(msg).then(() => {
          userUnBindThirdParty(that.user_id, type).then((res) => {
            if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                this.$emit('update:loginVisible', true);
                return false;
            }
            this.$alert('解除绑定成功！', '提示', {
                  confirmButtonText: '确定',
            });
            that.setUserInfo();
          }).catch((res) => {})
        }).catch(res => {})
      },

      setUserInfo() {
        let that = this;
        GetUserState({"email_status": true})
          .then((res) => {
            if (res.id) {
              that.user_id = res.id;
              that.editCellPhoneModal.form.user_id = res.id;
              that.account = res.username;
              that.tel = res.cellphone;
              that.origin_username = res.origin_username;
              that.job = res.job;
              that.job_identity = res.job_identity;
              that.work_place = res.work_place;
              that.realname = res.realname;
              that.job_name = res.job_name;
              that.email = res.email;
              that.email_verified = res.email_verified;
              that.qq = res.qq;
              that.weixin = res.weixin;
              that.weibo = res.weibo;
              that.baidu = res.baidu;
              that.github = res.github;
            }
          });
      },

      showBindMessage(msg) {
        this.$alert(`${msg}`, '提示', {
                  confirmButtonText: '确定',
            });
      }
    },
    mounted() {
      this.setUserInfo();
      window.resetUserInfo = this.setUserInfo;
      window.showLoginMessage = this.showBindMessage;
    },

    created() {

    },
  }
</script>

<style lang="scss" scoped>
  .part {
    border-bottom:2px solid #f0f0f1;
    margin-left:-15px;
    margin-right:-15px;
    margin-bottom:15px;
    padding-left:15px;
    padding-right:15px;
    &:last-child {
      border-bottom:none;
    }
    h3 {
      font-weight: normal;
      color:#333;
      margin-top: 0;
      padding-top: 10px;
      .el-button {
        float:right;
        position:relative;
        top:-5px;
      }
    }
    :deep(.edit_btns) {
      .el-form-item__content {
        margin-left:20px !important;
      }
    }
    :deep(.el-form-item) {
      .el-form-item__content {
        min-width:0;
        > span {
          display:block;
          white-space: nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          &.tip {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            position: relative;
            top: -2px;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .get_yzm {
    position:relative;
    .el-button, > span {
      position:absolute;
      right:10px;
      top:2px;
      font-size:12px;
    }
  }
</style>
