/**
 * 后台角色API
 */
import { fetch, post, del, postForm } from 'api/base/axios';

const URL = {
  checkPhoneUrl: '/user/register/cellphone/exist/',
  sendSmsUrl: '/user/register/sms_code/',
  registerUserUrl: '/user/register/signup/'
};

export function checkPhoneExist (cellphone) {
  return fetch(URL.checkPhoneUrl + cellphone + "/");
}

export function SendSms (cellphone) {
  return fetch(URL.sendSmsUrl, {"cellphone": cellphone});
}

export function RegisterUser (form) {
  return post(URL.registerUserUrl, form);
}
