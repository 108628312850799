<template>
  <div>
    <SliderVerify v-if="!isSuccess"
      :imgUrl="sliderVConf.imgUrl"
      :sText="sliderVConf.sText"
      :eText="sliderVConf.eText"
      v-model:isShowSelf="sliderVConf.isShowSelf"
      :isBorder="sliderVConf.isBorder"
      :isParentNode="sliderVConf.isParentNode"
      :isCloseBtn="sliderVConf.isCloseBtn"
      :isReloadBtn="sliderVConf.isReloadBtn"
      :width="sliderVConf.width"
      :height="sliderVConf.height"
      @reload="emitChange('reload')"
      @show="emitChange('show')"
      @hide="emitChange('hide')"
      @close="emitChange('close')"
      @success="emitChange('success')"
      @fail="emitChange('fail')"
      :class="{'slider_verify': true, 'ishover': ishover, 'extra_style': needExtraStyle}"
      @mousedown.stop="isDown = true"
      @mouseup="isDown = false"
    ></SliderVerify>
    <div class="puzzle_success" v-if="isSuccess">
      <i class="el-icon-success"></i> 校验成功
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      width: Number,
      height: Number,
      needExtraStyle: {
        type: Boolean,
        default: false
      }
    },
    emits: ['status','result'],
    data() {
      return {
        ishover: false,
        isSuccess: false,
        isDown: false,
        sliderVConf: {
          isShowSelf: true,
          width: this.width,
          height: this.height ? this.height : 180,
          imgUrl: require('/src/assets/img/pic/pic.jpg'), //自定义图片地址
          sText: '校验成功',
          eText: '校验失败',
          isBorder: false,
          isCloseBtn: false,
          isReloadBtn: true,
          isParentNode: false
        }
      }
    },
    created() {
      this.$emit('status', this.isSuccess);
    },
    mounted() {
      document.onmouseup = () => {
        if(this.isDown) {
          this.ishover = false;
          this.isDown = false;
        }
      }
      const verifyBtn = document.getElementById('circle');
      const verifyImg = document.getElementById('canvas_containe');
      const verifyRefresh = document.querySelector('.operational');
      verifyBtn.addEventListener('mouseenter', () => {
        this.ishover = true;
      })
      verifyBtn.addEventListener('mouseleave', () => {
        this.mouseLeaveHandler();
      })
      verifyImg.addEventListener('mouseenter', () => {
        this.ishover = true;
      })
      verifyImg.addEventListener('mouseleave', () => {
        this.mouseLeaveHandler();
      })
      verifyRefresh.addEventListener('mouseenter', () => {
        this.ishover = true;
      })
    },
    methods: {
      emitChange(type) {
        if(type === 'success') {
          this.isSuccess = true;
        }
        this.$emit('status', this.isSuccess);
        this.$emit('result', type);
      },
      mouseLeaveHandler() {
        if(this.isDown) {
          this.ishover = true;
        } else {
          this.ishover = false;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep(#slider-verify) {
    padding:0;
    border-radius: 0;
    overflow:visible;
    width:100%;
    #verify_containe {
      padding-bottom:0;
      width:100%;
      #canvas_containe {
        display:none;
        padding-bottom:10px;
        position: absolute;
        bottom: 45px;
        z-index:1;
      }
      .slide-box {
        height:45px;
        border-radius:4px;
        background:#f3f3f3;
        margin-top:0;
        #circle {
          height:45px;
          border-radius:4px;
          top:0;
        }
      }
      .operational {
        display:none;
        padding:0;
        border-top:none;
        position:absolute;
        right:10px;
        width: auto;
        top: -180px;
        left: auto;
        z-index: 1;
      }
    }
  }
  .slider_verify.ishover {
    :deep(#canvas_containe), :deep(.operational) {
      display:block !important;
    }
  }
  .puzzle_success {
    height:45px;
    border-radius:4px;
    line-height:45px;
    text-align:center;
    background:#F0F9EB;
    color:#4BC51C;
  }
</style>
